<template>
  <vue-html2pdf
    :show-layout="true"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="false"
    :paginate-elements-by-height="1400"
    filename="extrato_de_transação"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="100%"
    ref="html2Pdf"
  >
    <section slot="pdf-content">
      <q-banner inline-actions class="text-white bg-red text-center" v-if="invoiceError">
        Invoice cancelada ou inexistente
      </q-banner>

      <q-page class="flex flex-center bg-grey">
        <q-card flat class="bg-white" style="width: 800px">
          <q-card-section class="row">
            <div class="col-4">
              <q-img :src="require('@/assets/images/logo-afilio.png')" style="width: 150px" />
              <q-item>
                <q-item-section>
                  <q-item-label caption>HI-MIDIA INTERNET LTDA.</q-item-label>
                  <q-item-label caption>CNPJ: 07.456.778/0001-59</q-item-label>
                  <q-item-label caption lines="3"
                    >Rua Humaita, 275 - 6º andar/7º andar - Humaita - Cep: 22.261-005 Rio de Janeiro - RJ Brasil
                  </q-item-label>
                </q-item-section>
              </q-item>
            </div>
            <div class="col-8">
              <q-item class="q-pa-none">
                <q-item-section class="q-pa-none">
                  <q-item-label class="text-h3 text-grey q-pb-none">Fatura </q-item-label>
                  <q-item-label class="text-h5 text-grey"> Invoice / Factura </q-item-label>
                </q-item-section>
              </q-item>
              <q-item class="q-pa-none">
                <q-item-section class="q-pa-none">
                  <q-item-label caption>Nº da fatura:</q-item-label>
                </q-item-section>
                <q-item-section>
                  {{ invoicesNumbers.join(', ') }}
                </q-item-section>
              </q-item>
              <q-item class="q-pa-none">
                <q-item-section class="q-pa-none">
                  <q-item-label caption>Data da fatura:</q-item-label>
                </q-item-section>
                <q-item-section> {{ $moment(invoiceData.outbound_invoice[0].created_at).format('L') }}</q-item-section>
              </q-item>
              <q-item class="q-pa-none">
                <q-item-section class="q-pa-none">
                  <q-item-label caption>Invoice date / fecha de la factura:</q-item-label>
                </q-item-section>
                <q-item-section>( {{ $moment(invoiceData.outbound_invoice[0].created_at).format('L') }} )</q-item-section>
              </q-item>
            </div>
            <div class="col-12">
              <q-card flat>
                <q-card-section class="row q-mt-md">
                  <div class="col-1">
                    <div
                      class="bg-grey-3 absolute text-center"
                      style="transform: rotate(270deg); left: -45px; bottom: 40px"
                    >
                      <q-item>
                        <q-item-section>
                          <q-item-label caption>AFILIADO</q-item-label>
                          <q-item-label caption>Publisher/Afliado</q-item-label>
                        </q-item-section>
                      </q-item>
                    </div>
                  </div>
                  <div class="col-6">
                    <q-item>
                      <q-item-section>
                        <q-item-label caption
                          >Razão Social: {{ invoiceData.affiliate_bank.business_name }}</q-item-label
                        >

                        <q-item-label caption
                          >{{
                            invoiceData.affiliate_bank.document_type == 'INTER'
                              ? 'VAT/NIF/TIN'
                              : invoiceData.affiliate_bank.document_type
                          }}: {{ invoiceData.affiliate_bank.document_number }}</q-item-label
                        >
                        <q-item-label caption lines="3"
                          >{{ invoiceData.affiliate.address }}, {{ invoiceData.affiliate.address_number }} -
                          {{ invoiceData.affiliate.neighborhood }}
                          {{ invoiceData.affiliate.city }} -
                          {{ invoiceData.affiliate.zip_code }}
                          {{ invoiceData.affiliate.country }}
                        </q-item-label>
                        <q-item-label caption
                          >Nº de identificação/ID do Afiliado: {{ invoiceData.affiliate.id }}</q-item-label
                        >
                      </q-item-section>
                    </q-item>
                  </div>
                  <div class="col-5">
                    <q-list bordered padding>
                      <q-item>
                        <q-item-section>
                          <q-item-label class="text-weight-bold">Valor (BRL)</q-item-label>
                          <q-item-label caption>Balance / Valor</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-item-label class="text-weight-bold">
                            R$ {{ invoiceData.total_commissions_value }}
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                </q-card-section>
                <q-card-section class="bg-grey-4">
                  <q-item>
                    <q-item-section>
                      <q-item-label class="text-weight-bold">Description</q-item-label>
                      <q-item-label caption lines="2">Description / Descripción</q-item-label>
                    </q-item-section>
                    <q-item-section side top>
                      <q-item-label class="text-weight-bold">Total</q-item-label>
                      <q-item-label caption>Total / Total</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-card-section>
                <q-card-section class="bg-grey-3">
                  <q-item>
                    <q-item-section>
                      <q-item-label class="text-weight-bold">Locação de espaço virtual para campanhas </q-item-label>
                      <q-item-label caption lines="2">
                        Lease of virtual space / Alquiler de espacio virtual
                      </q-item-label>
                    </q-item-section>
                    <q-item-section side top>
                      <q-item-label class="text-weight-bold">
                        R$ {{ invoiceData.total_commissions_value }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-card-section>
              </q-card>
            </div>
          </q-card-section>
          <q-card-section>
            <q-card flat bordered>
              <q-card-section class="bg-grey text-center text-white">
                <div class="text-h6">DADOS BANCÁRIOS DO AFILIADO</div>
                <div class="text-subtitle2">Publisher bank account details / Datos bancarios del afliado</div>
              </q-card-section>
              <q-card-section class="text-center">
                <q-item v-if="invoiceData.affiliate_bank.bank_country === 'Brasil'">
                  <q-item-section>
                    <q-item-label> Código do Banco: {{ invoiceData.affiliate_bank.bank_name }} </q-item-label>
                    <q-item-label caption lines="2">
                      Agência: {{ invoiceData.affiliate_bank.bank_agency }}
                    </q-item-label>
                    <q-item-label caption lines="2"
                      >Conta:
                      {{ invoiceData.affiliate_bank.bank_account_number }}
                      - {{ invoiceData.affiliate_bank.bank_account_number_digit }}
                    </q-item-label>
                    <q-item-label caption lines="2">Endereço do Banco: </q-item-label>
                    <q-item-label caption lines="2">País: Brasil </q-item-label>
                    <q-item-label caption lines="2"
                      >Tipo de Chave Pix: {{ invoiceData.affiliate_bank.pix_type }}
                    </q-item-label>
                    <q-item-label caption lines="2">Chave Pix: {{ invoiceData.affiliate_bank.pix_key }} </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item v-else>
                  <q-item-section>
                    <q-item-label> Bank Country: {{ invoiceData.affiliate_bank.bank_country }} </q-item-label>
                    <q-item-label caption lines="2" v-if="invoiceData.affiliate_bank.aba !== null">
                      aba: {{ invoiceData.affiliate_bank.aba }}
                    </q-item-label>
                    <q-item-label caption lines="2" v-if="invoiceData.affiliate_bank.routing_number !== null">
                      routing number: {{ invoiceData.affiliate_bank.routing_number }}
                    </q-item-label>
                    <q-item-label caption lines="2" v-if="invoiceData.affiliate_bank.iban !== null">
                      iban: {{ invoiceData.affiliate_bank.iban }}
                    </q-item-label>
                    <q-item-label caption lines="2" v-if="invoiceData.affiliate_bank.swift !== null">
                      swift: {{ invoiceData.affiliate_bank.swift }}
                    </q-item-label>
                    <q-item-label caption lines="2" v-if="invoiceData.affiliate_bank.bic !== null">
                      bic: {{ invoiceData.affiliate_bank.bic }}
                    </q-item-label>
                    <q-item-label caption lines="2" v-if="invoiceData.affiliate_bank.other_type !== null">
                      {{ invoiceData.affiliate_bank.other_type }}: {{ invoiceData.affiliate_bank.other_value }}
                    </q-item-label>
                    <q-item-label caption lines="2" v-if="invoiceData.affiliate_bank.bank_address !== null">
                      bank address: {{ invoiceData.affiliate_bank.bank_address }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-card-section>
            </q-card>
          </q-card-section>
          <q-separator spaced inset />
          <q-card-section class="q-px-xl">
            <div class="text-h6">OBSERVAÇÃO</div>
            <div class="text-subtitle2">Note / Observación</div>
            <div class="text-caption">
              O detalhamento das campanhas referente ao valor desta fatura encontra-se no corpo do e-mail. The amount
              breakdown per campaign has been sent to you by email. Los detalles de las campañas referentes al valor de
              esta factura se encuentran en el cuerpo del email.
            </div>
          </q-card-section>
          <q-separator spaced inset />
          <q-card-section class="q-px-xl row">
            <div class="col-4">
              <div class="text-h6">PAGAMENTO</div>
              <div class="text-subtitle2">Payment / Pago</div>
            </div>
            <div class="col-8">
              <q-card flat bordered>
                <q-card-section class="bg-grey-4 q-pa-none">
                  <q-item>
                    <q-item-section>
                      <q-item-label>Cliente</q-item-label>
                      <q-item-label caption lines="2">Bill to / Cliente </q-item-label>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="text-h5">HI-MIDIA INTERNET LTDA. </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-card-section>
                <q-card-section class="q-pa-none">
                  <q-item>
                    <q-item-section>
                      <q-item-label>CNPJ</q-item-label>
                      <q-item-label caption>VAT</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-item-label>07.456.778/0001-59</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label>Endereço</q-item-label>
                      <q-item-label caption>Address / Dirección</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-item-label>Rua Humaita, 275 - 6º andar/7º andar - Humaita</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label>CEP - Cidade - Estado</q-item-label>
                      <q-item-label caption>Zip Code - City - State </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-item-label>22.261-005 - Rio de janeiro - RJ </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-card-section>
              </q-card>
            </div>
          </q-card-section>
        </q-card>
      </q-page>

      <q-banner inline-actions class="text-white bg-red text-center" v-if="invoiceError">
        Invoice cancelada ou inexistente
      </q-banner>
    </section>
  </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import FinanceService from '../../services/FinanceService';

export default {
  name: 'OutBoundInvoice',
  components: { VueHtml2pdf },
  mixins: [FinanceService],
  props: {
    hash: String
  },
  data() {
    return {
      invoiceError: false,
      invoicesNumbers: [],
      invoiceData: {
        total_commissions_value: null,
        outbound_invoice: [],
        affiliate: {
          id: null,
          token: null,
          name: null,
          last_name: null,
          business_name: null,
          email: null,
          phone_number: null,
          address: null,
          address_number: null,
          address_other: null,
          zip_code: null,
          neighborhood: null,
          city: null,
          state: null,
          country: null,
          site: null,
          currency: null,
          language: null,
          status: null,
          created_at: null,
          updated_at: null
        },
        affiliate_bank: [
          {
            id: null,
            affiliate_id: null,
            document_type: null,
            document_number: null,
            business_name: null,
            trademark: null,
            email: null,
            phone_number: null,
            minimum_payment_value: null,
            bank_country: null,
            bank_name: null,
            bank_agency: null,
            bank_account_number: null,
            bank_account_number_digit: null,
            bank_account_type: null,
            aba: null,
            routing_number: null,
            iban: null,
            swift: null,
            bic: null,
            other_type: null,
            other_value: null,
            bank_address: null,
            currency: null,
            created_at: null,
            updated_at: null
          }
        ]
      }
    };
  },
  methods: {
    async importInvoice() {
      this.onLoading(true);

      try {
        const { data, status } = await this.getOutboundInvoice(this.hash);
        if (status === 200 || status === 201) {
          this.invoiceData = data.data;

          this.invoicesNumbers = this.invoiceData.outbound_invoice.map(invoice => invoice.id_invoices_payments);

          let formatValue = parseFloat(this.invoiceData.total_commissions_value);
          this.invoiceData.total_commissions_value = formatValue
            .toFixed(2)
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

          this.invoiceData.affiliate_bank = this.invoiceData.affiliate_bank[0];
        }
      } catch (error) {
        this.invoiceError = true;
      } finally {
        this.onLoading(false);
      }
    },

    printPdf() {
      this.importInvoice();

      this.$refs.html2Pdf.generatePdf();
    }
  },
  mounted() {
    this.printPdf();
  }
};
</script>

<style></style>
